import { WebAtendimentoDemandaService } from './../../../shared/services/webAtendimentoDemanda.service';
import { WebAtendimentoDemandaResponse } from './../../../shared/models/webAtendimentoDemandaResponse.model';

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { WebAtendimentoInteracao } from 'app/main/content/shared/models/webAtendimentoInteracao.model';
import { WebAtendimentoInteracaoService } from 'app/main/content/shared/services/webAtendimentoInteracao.service';
import { FuseThemeOptionsComponent } from '@fuse/components/theme-options/theme-options.component';
import * as _ from 'lodash';

@Component({
    selector: 'app-modal-visualizar',
    templateUrl: './modal-visualizar.component.html',
    styleUrls: ['./modal-visualizar.component.scss']
})
export class ModalVisualizarComponent implements OnInit {
    idDemanda: number;
    interacao: WebAtendimentoInteracao = new WebAtendimentoInteracao();
    dtEnvio: Date;
    webAtendimentoInteracaoRetorno: WebAtendimentoInteracao = new WebAtendimentoInteracao();
    webAtendimentoInteracao: WebAtendimentoInteracao[] = [];
    constructor(public dialogConfirm: MatDialog,
                private webAtendimentoInteracaoService: WebAtendimentoInteracaoService,
                public dialogRef: MatDialogRef<ModalVisualizarComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any ) {
        if (this.data) {
            this.idDemanda = data.idDemanda,
                this.getByIdDemanda(); 
            
        }
    }
    
    ngOnInit() {   
    
    }
    getByIdDemanda() {
        this.webAtendimentoInteracaoService.getByIdDemanda(this.idDemanda).subscribe(retorno => {
            //this.webAtendimentoInteracao = retorno;
            this.webAtendimentoInteracao = _.orderBy(retorno, ['dtEnvio'], ['asc']);
            console.log('aqui retorna Interacao', retorno);
             
        });
    }
    criar() {
        this.interacao.idDemanda = this.idDemanda;
        this.webAtendimentoInteracaoService.criar(this.interacao).subscribe(resposta => {
            this.webAtendimentoInteracaoRetorno = resposta ;          
            this.getByIdDemanda();
            this.interacao.nmInteracao = undefined;
        });    
    }
}

