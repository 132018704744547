
import { WebAtendimentoInteracao } from './../../shared/models/webAtendimentoInteracao.model';
import { WebAtendimentoInteracaoService } from './../../shared/services/webAtendimentoInteracao.service';
import { ModalVisualizarComponent } from './modal-visualizar/modal-visualizar.component';
import { WebAtendimentoDemandaRequest } from './../../shared/models/webAtendimentoDemandaRequest.model';
import { WebAtendimentoDemandaResponse } from './../../shared/models/webAtendimentoDemandaResponse.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { WebAtendimentoDemandaService } from '../../shared/services/webAtendimentoDemanda.service';


@Component({
    selector: 'app-pergunta-resposta',
    templateUrl: './pergunta-resposta.component.html',
    styleUrls: ['./pergunta-resposta.component.scss']
})
export class PerguntaRespostaComponent implements OnInit {
    pageIndex = "";
    pageSize = "";
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    dataSource = new MatTableDataSource<any>();
    displayedColumns: string[] = ['cpf', 'nome', 'categoria', 'titulo', 'criada', 'interacao', 'acoes'];
    webAtendimentoDemandaResponse: WebAtendimentoDemandaResponse[] = [];
    webAtendimentoInteracao: WebAtendimentoInteracao[] = [];
    webAtendimentoDemandaRequest: WebAtendimentoDemandaRequest = new WebAtendimentoDemandaRequest();
    cpf: string = '';
    nome: string = '';
    idCategoria: string = '';
    titulo: string = '';
    dataInicio: string = '';
    dataFim: string = '';
    icNaoLidaAdm: string;
 
    constructor(
        public dialog: MatDialog,
        private webAtendimentoDemandaService: WebAtendimentoDemandaService,
        public webAtendimentoInteracaoService: WebAtendimentoInteracaoService
        
    ) { }

    ngOnInit() {
      this.getListaAtendimentoDemanda();

    }
    
    abrirModal(element: WebAtendimentoDemandaResponse) {
        console.log('Dados do id demanda selecionado', element);
        const dialogRef = this.dialog.open(ModalVisualizarComponent,{
            width: '65%',
            height: 'auto',
            data:element
        });
    
        dialogRef.afterClosed().subscribe(result => {
          console.log(`Dialog result: ${result}`);
        });
      }
    

    
    getListaAtendimentoDemanda(){
        this.webAtendimentoDemandaService.getListaAtendimentoDemanda(this.webAtendimentoDemandaRequest).subscribe(retorno =>{
            this.webAtendimentoDemandaResponse = retorno;
            this.dataSource = new MatTableDataSource<WebAtendimentoDemandaResponse>(retorno);
            this.dataSource.paginator = this.paginator;
                console.log('aqui', retorno);

        });

    }
   
    filtrar() {
        this.webAtendimentoDemandaRequest.nome = this.nome;
        this.webAtendimentoDemandaRequest.cpf = this.cpf.replace('.','').replace('.','').replace('-', '');
        this.webAtendimentoDemandaRequest.idCategoria = this.idCategoria;
        this.webAtendimentoDemandaRequest.titulo = this.titulo;
        this.webAtendimentoDemandaRequest.dtInicio = this.dataInicio;
        this.webAtendimentoDemandaRequest.dtFim = this.dataFim;
        console.log(this.webAtendimentoDemandaRequest);
        this.webAtendimentoDemandaService.getListaAtendimentoDemanda(this.webAtendimentoDemandaRequest).subscribe(obj => {
            this.webAtendimentoDemandaResponse = obj;
            this.dataSource = new MatTableDataSource<WebAtendimentoDemandaResponse>(this.webAtendimentoDemandaResponse);
            this.dataSource.paginator = this.paginator;
            console.log('aqui aqui', obj);
         
        });
    }

    limpar() {
        this.nome = '';
        this.cpf = '';
        this.idCategoria = '';
        this.titulo = '';
        this.dataInicio = '';
        this.dataFim = '';

       
        this.webAtendimentoDemandaRequest.nome = '';
        this.webAtendimentoDemandaRequest.cpf = '';
        this.webAtendimentoDemandaRequest.idCategoria = '';
        this.webAtendimentoDemandaRequest.titulo = '';
        this.webAtendimentoDemandaRequest.dtInicio = '';
        this.webAtendimentoDemandaRequest.dtFim = '';

        this.getListaAtendimentoDemanda();
    }
 
}
