
import { WebAtendimentoDemandaResponse } from './../models/webAtendimentoDemandaResponse.model';
import { WebAtendimentoDemandaRequest } from './../models/webAtendimentoDemandaRequest.model';
import { ApiService } from './api.services';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export class WebAtendimentoDemandaService extends ApiService {

    private strAction = 'api/WebAtendimentoDemanda';
    
    static buildQueryParams(source: Object): HttpParams {let target: HttpParams = new HttpParams();Object.keys(source).forEach((key: string) => {const value: string | number | boolean | Date = source[key];if ((typeof value !== 'undefined') && (value !== null)) {target = target.append(key, value.toString());}});return target;}


    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

   // getListaAtendimentoDemanda(nome:string, cpf:string, idCategoria:number, titulo:string, dtInicio:Date, dtFim:Date ): Observable<WebAtendimentoDemandaResponse[]> {
      //  return this.get<WebAtendimentoDemandaResponse[]>(this.strAction + `/ListaAtendimentoDemanda`, );
        
    //}
     getListaAtendimentoDemanda(webAtendimentoDemanda: WebAtendimentoDemandaRequest ): Observable<WebAtendimentoDemandaResponse[]> {
        let parameters = {"nome":webAtendimentoDemanda.nome, "cpf":webAtendimentoDemanda.cpf, "nmTituloDemanda":webAtendimentoDemanda.titulo,"idCategoria" :webAtendimentoDemanda.idCategoria, "dtInicio" : webAtendimentoDemanda.dtInicio, "dtFim" : webAtendimentoDemanda.dtFim };
        let queryParams = new HttpParams({ fromObject: parameters }); 
      return this.get<WebAtendimentoDemandaResponse[]>(this.strAction + `/ListaAtendimentoDemanda`, {params: queryParams} );
        
    }
    //filtrar(webAtendimentoDemanda: WebAtendimentoDemandaRequest ): Observable<WebAtendimentoDemandaResponse[]> {
    
      //return this.post<WebAtendimentoDemandaResponse[]>(this.strAction + `/Filtrar`, webAtendimentoDemanda );
        
  // }
}