import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "environments/environment";

export class BaixaCobrancaService {

    private strAction = 'api/BaixaCobranca';

    constructor(
        private http: HttpClient,
        private route: Router) {
    }


    Post() {
        return this.http.post<void>(`${environment.TrustApiCobrancaHost}${this.strAction}/BaixaCobranca`, '');
    }


}