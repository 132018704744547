import { BaixaCobrancaService } from './../shared/services/baixaCobranca.service';
import { RegistroCobrancaService } from './../shared/services/registroCobranca.service';
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MatSnackBar } from '@angular/material';
import { ViewModelEnviaCobranca } from '../shared/models/enviaCobranca.model';

@Component({
    selector: 'app-integracaobancaria',
    templateUrl: './integracaobancaria.component.html',
    styleUrls: ['./integracaobancaria.component.scss']
})

export default class IntegracaoBancariaComponent implements OnInit {

    dataVencimento: Date;
    viewModelEnviaCobranca: ViewModelEnviaCobranca = new ViewModelEnviaCobranca();

    constructor(
        private readonly router: Router,
        private _snackBar: MatSnackBar,
        private readonly registroCobrancaService: RegistroCobrancaService,
        private readonly baixaCobrancaService: BaixaCobrancaService

    ) {


    }

    ngOnInit() {

    }

    enviaCobranca() {
        
        this.viewModelEnviaCobranca.dataVencimento = this.dataVencimento;
        
        this.registroCobrancaService.Post(this.viewModelEnviaCobranca).subscribe(retorno => {

            this._snackBar.open('A solicitação foi realizada com sucesso.', 'X', {
                duration: 6000,
                panelClass: ['green-snackbar']

            })


        }, error => {
            this._snackBar.open(error.error, 'X', {
                duration: 8000,
                panelClass: ['red-snackbar']
            })
        });
    }

    baixaCobranca() {
        this.baixaCobrancaService.Post().subscribe(retorno => {

            this._snackBar.open('A solicitação foi realizada com sucesso.', 'X', {
                duration: 6000,
                panelClass: ['green-snackbar']

            })


        }, error => {
            this._snackBar.open('Erro ao realizar a solicitação.', 'X', {
                duration: 8000,
                panelClass: ['red-snackbar']
            })
        });
    }

}