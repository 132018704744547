import { NgModule } from "@angular/core";
import { MatButtonModule, MatDatepickerModule, MatFormFieldModule, MatInputModule } from "@angular/material";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { Ng4LoadingSpinnerModule } from "ng4-loading-spinner";
import { SharedModule } from "../shared/shared.module";
import IntegracaoBancariaComponent from "./integracaobancaria.component";

const routes = [
    {
        path: 'integracaobancaria',
        component: IntegracaoBancariaComponent
    }
];


@NgModule({
    declarations: [IntegracaoBancariaComponent],
    imports: [
        RouterModule.forChild(routes),
        SharedModule,
        Ng4LoadingSpinnerModule,
        MatButtonModule,
        FuseSharedModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
    ],
    exports: [IntegracaoBancariaComponent]
})
export class IntegracaoBancariaModule { }
