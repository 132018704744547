
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { WebAtendimentoInteracao } from "../models/webAtendimentoInteracao.model";
import { ApiService } from "./api.services";


export class WebAtendimentoInteracaoService extends ApiService {

    private strAction = 'api/WebAtendimentoInteracao';
    dialogData: any;
    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getByIdDemanda(idDemanda: number): Observable<WebAtendimentoInteracao[]> {
        return this.get(this.strAction + `/GetByIdDemanda?IdDemanda=${idDemanda}`);
    }

    criar(interacao: WebAtendimentoInteracao) {
        return this.post<WebAtendimentoInteracao>(this.strAction + `/Criar`, interacao);
      
    }
        
  }
